/**
 * Document loading utilities
 * @module modules/legal/utils/loader
 */

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../../../main.js";

/**
 * Load a legal document's HTML content
 * @param {string} documentId - The document identifier (terms, privacy, cookies)
 * @returns {Promise<{content: string, metadata: Object}>}
 */
export async function loadDocument(documentId) {
  try {
    // First try to get from Firestore for metadata
    const db = getFirestore(app);
    const docRef = doc(db, "legal_documents", documentId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error(`Document ${documentId} not found`);
    }

    const data = docSnap.data();
    const metadata = {
      updatedAt: data.fileMetadata?.updated || new Date().toISOString(),
      version: data.version || "1.0.0",
    };

    // Get HTML content from our Express endpoint
    const baseUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:5002" // Development server
        : ""; // Production (relative path)

    const url = `${baseUrl}/app/data/legal/${documentId}`;
    console.log("Requesting document from:", url);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch document: ${response.statusText}`);
    }

    const content = await response.text();
    console.log("Raw content from endpoint:", {
      length: content.length,
      firstChars: content.substring(0, 100),
      hasLegalContent: content.includes("legal-content"),
      contentType: response.headers.get("content-type"),
    });

    // Extract just the legal-content div from the HTML
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(content, "text/html");
    const legalContent = parsedDoc.querySelector(".legal-content");

    console.log("Parsed content:", {
      foundLegalContent: !!legalContent,
      parsedContent: legalContent
        ? legalContent.outerHTML.substring(0, 100)
        : "Not found",
      documentElement: parsedDoc.documentElement.outerHTML.substring(0, 100),
    });

    // If we found the content, return it, otherwise return the full content
    const finalContent = legalContent ? legalContent.outerHTML : content;

    return { content: finalContent, metadata };
  } catch (error) {
    console.error(`Error loading document ${documentId}:`, error);
    throw error;
  }
}
