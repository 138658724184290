/**
 * @author Thomas S. Butler
 * @abstract Form validation component orchestrating the validation flow
 * @copyright 2022-2024 Firesite LLC
 */

import { AutofillPrevention } from "../utils/autofill.js";

export class FormValidation {
  constructor(form) {
    this.form = form;
    this.submitButton = this.form.element.querySelector(
      'button[type="submit"]'
    );
    this.isWebkit = navigator.userAgent.indexOf("AppleWebKit") > -1;
    this.timestamp = Date.now();

    // Initialize form
    this.killAutoComplete();
    this.setupFormListeners();
    this.initializeInputs();

    // Mark as ready after setup
    this.form.element.classList.add("ready");

    // Initially disable submit button
    if (this.submitButton) {
      this.submitButton.disabled = true;
    }
  }

  /**
   * Initialize all inputs
   */
  initializeInputs() {
    this.form.inputs.forEach(({ element: input }) => {
      // Skip hidden inputs
      if (input.type === "hidden") return;

      // Set initial states
      this.checkDirtyInput(input);

      // Handle Chrome autofill
      if (this.isWebkit && input.matches(":-webkit-autofill")) {
        input.closest(".input-control")?.classList.add("dirty");
      }

      // Add real-time validation listeners with debounce for typing
      let validationTimeout;
      const validateInRealTime = () => {
        clearTimeout(validationTimeout);
        validationTimeout = setTimeout(() => {
          // Only validate if the input has a value or is required
          if (input.value.trim() || input.hasAttribute("required")) {
            this.validateInput(input);
            // Update dirty state after validation
            this.checkDirtyInput(input);
          }
        }, 200); // Small delay to avoid too frequent validation
      };

      // Immediate validation for paste and change
      const validateImmediate = () => {
        if (input.value.trim() || input.hasAttribute("required")) {
          this.validateInput(input);
          this.checkDirtyInput(input);
        }
      };

      input.addEventListener("input", validateInRealTime); // Changed from keyup to input
      input.addEventListener("paste", () => setTimeout(validateImmediate, 0));
      input.addEventListener("change", validateImmediate);
    });

    // Update submit button state after initialization
    this.updateSubmitButton();
  }

  /**
   * Kill autocomplete functionality
   */
  killAutoComplete() {
    this.form.inputs.forEach(({ element: input }) => {
      if (
        !input.classList.contains("selectbox") &&
        !input.classList.contains("textbox")
      ) {
        // Store original name
        input.dataset.inputname = input.name;

        // Set random form name and autocomplete
        const timestamp = this.timestamp;
        input.name = input.dataset.inputname + timestamp;
        input.autocomplete = "new-" + input.name + timestamp;
      }
    });
  }

  /**
   * Sets up form event listeners
   */
  setupFormListeners() {
    // Handle form submit
    this.form.element.addEventListener("submit", (e) => {
      e.preventDefault();

      if (this.validateAllFields()) {
        // Disable all inputs before submit
        this.form.inputs.forEach(({ element: input }) => {
          input.disabled = true;
        });

        this.form.element.dispatchEvent(
          new CustomEvent("fs:submit", {
            detail: { data: this.getFormData() },
            bubbles: true,
          })
        );
      }
    });

    // Handle keydown for enter key and tab key
    this.form.element.addEventListener("keydown", (e) => {
      const activeElement = document.activeElement;
      const control = activeElement?.closest(".input-control");

      // Handle tab key when form input has focus
      if (e.keyCode === 9 && control) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      // Handle enter key
      if (e.keyCode === 13) {
        // Enter key
        e.preventDefault();

        // Don't submit if in textbox
        if (control?.classList.contains("textboxinput")) {
          return;
        }

        // Submit if button is enabled
        if (this.submitButton && !this.submitButton.disabled) {
          setTimeout(() => this.submitButton.click(), 50);
        }
      }
    });
  }

  /**
   * Check if input is dirty and update state
   */
  checkDirtyInput(input) {
    const control = input.closest(".input-control");
    if (!control) return;

    const value = input.value.trim();

    if (!value) {
      control.classList.remove("dirty");
      // Don't remove valid/invalid classes here - let validation handle that
    } else {
      control.classList.add("dirty");
    }

    // Handle Chrome autofill
    if (this.isWebkit && input.matches(":-webkit-autofill")) {
      control.classList.add("dirty");
    }

    // Update submit button after state change
    this.updateSubmitButton();
  }

  /**
   * Validate a single input
   */
  validateInput(input) {
    const control = input.closest(".input-control");
    if (!control) return true;

    const value = input.value.trim();

    // Handle required fields - invalid if not dirty
    if (input.hasAttribute("required") && !value) {
      control.classList.add("invalid");
      control.classList.remove("valid");
      return false;
    }

    // Remove invalid state before validation
    control.classList.remove("invalid");

    // Type-specific validation
    if (input.type === "email" && value) {
      if (!this.isValidEmailAddress(value)) {
        control.classList.add("invalid");
        control.classList.remove("valid");
        return false;
      } else {
        control.classList.remove("invalid");
        control.classList.add("valid");
        input.value = this.emailCaseFix(value);
      }
    }

    // Phone validation
    if (input.classList.contains("phone") && value) {
      if (!this.isValidPhoneNumber(value)) {
        control.classList.add("invalid");
        control.classList.remove("valid");
        return false;
      } else {
        control.classList.remove("invalid");
        control.classList.add("valid");
      }
    }

    // If we get here and have a value, it's valid
    if (value) {
      control.classList.add("valid");
    } else {
      control.classList.remove("valid");
    }

    // Update submit button after validation
    this.updateSubmitButton();
    return !control.classList.contains("invalid");
  }

  /**
   * Validate all fields in the form
   */
  validateAllFields() {
    let isValid = true;
    let invalidCount = 0;

    this.form.inputs.forEach(({ element: input }) => {
      // Skip ignored fields
      if (
        input.classList.contains("ignore") ||
        input.classList.contains("datepart") ||
        input.classList.contains("timepart") ||
        input.type === "hidden"
      ) {
        return;
      }

      if (!this.validateInput(input)) {
        isValid = false;
        invalidCount++;
      }
    });

    // Show appropriate messages
    if (invalidCount > 0) {
      if (invalidCount > 2) {
        this.showMessage(
          "Invalid form submission. Please check your entries.",
          "error"
        );
      } else {
        const invalids = this.form.element.querySelectorAll(
          ".input-control.invalid"
        );
        invalids.forEach((control) => {
          const input = control.querySelector("input");
          const message = input.dataset.message || "This field is invalid";
          this.showMessage(message, "error");
        });
      }
    }

    return isValid;
  }

  /**
   * Update submit button state
   */
  updateSubmitButton() {
    if (!this.submitButton) return;

    const hasInvalidFields = this.form.element.querySelector(
      ".input-control.invalid"
    );
    const requiredFields = Array.from(
      this.form.element.querySelectorAll("input[required]")
    );

    // Check if all required fields are filled and dirty
    const allRequiredFieldsValid = requiredFields.every((input) => {
      const control = input.closest(".input-control");
      const value = input.value.trim();
      const isDirty =
        control.classList.contains("dirty") ||
        (this.isWebkit && input.matches(":-webkit-autofill"));

      return value && isDirty && !control.classList.contains("invalid");
    });

    this.submitButton.disabled = !allRequiredFieldsValid || hasInvalidFields;
  }

  /**
   * Show validation message
   */
  showMessage(message, type) {
    this.form.element.dispatchEvent(
      new CustomEvent("fs:validation-message", {
        detail: { message, type },
        bubbles: true,
      })
    );
  }

  /**
   * Email validation regex
   */
  isValidEmailAddress(email) {
    // First check TLD length
    const parts = email.split(".");
    if (parts.length < 2 || parts[parts.length - 1].length < 2) {
      return false;
    }

    // Then run the complex regex validation
    const pattern = new RegExp(
      /^((([a-z]|\d|[!#\$%&"\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&"\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );
    return pattern.test(email);
  }

  /**
   * Phone number validation
   */
  isValidPhoneNumber(phone) {
    const numbers = phone.replace(/[^0-9]+/g, "");
    return /^[0-9]{10}$/.test(numbers);
  }

  /**
   * Fix email case
   */
  emailCaseFix(email) {
    const [name, domain] = email.split("@");
    return name + "@" + domain.toLowerCase();
  }

  /**
   * Get form data
   */
  getFormData() {
    const data = {};
    this.form.inputs.forEach(({ element: input }) => {
      // Use original name if it exists
      const name = input.dataset.inputname || input.name;
      data[name] = input.value;
    });
    return data;
  }

  /**
   * Reset validation state
   */
  reset() {
    this.form.element.querySelectorAll(".input-control").forEach((control) => {
      control.classList.remove("invalid", "dirty");
    });
    this.updateSubmitButton();
  }
}
