// Handles form submission and validation
import { stepValidation } from "../config/states.js";
import { formStates } from "../config/formStates.js";
import { formSteps } from "../config/formSteps.js";

export class FormSubmitHandler {
  constructor(signupFlow) {
    this.signupFlow = signupFlow;
  }

  async handleSubmit(event, form) {
    console.log("Form submission handler called");
    event.preventDefault();

    // Don't process if already processing
    if (this.signupFlow.state.isProcessing) {
      console.log("Form is already processing");
      return;
    }

    // For email step, get the input value directly like the original implementation
    if (this.signupFlow.state.currentStep === "enter-email") {
      const emailInput = form.element.querySelector("#emailAddress");
      if (!emailInput) {
        console.error("Email input not found");
        return;
      }

      const email = emailInput.value;
      console.log("Submitting with email:", email);

      try {
        this.signupFlow.state.isProcessing = true;
        form.element.classList.add("submitting");
        document.body.classList.add("loading");
        this.signupFlow.updateFormState("enter-email", "validating");

        const response = await fetch("/app/invites/check", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        console.log("API response:", data);

        // Ensure minimum loading time
        await new Promise((resolve) => setTimeout(resolve, 2400));

        // Remove loading states before state updates
        form.element.classList.remove("submitting");
        document.body.classList.remove("loading");

        if (data.hasInvite) {
          // Update form data with validated data
          this.signupFlow.state.formData = {
            ...this.signupFlow.state.formData,
            ...data.invite,
            email,
          };

          // Handle successful invite found
          await this.handleInviteFound(form, emailInput);
        } else {
          await this.handleInviteNotFound(form, emailInput);
        }
      } catch (error) {
        console.error("API call error:", error);
        form.element.classList.remove("submitting");
        document.body.classList.remove("loading");
        this.signupFlow.showError("An error occurred checking your invitation");
      } finally {
        this.signupFlow.state.isProcessing = false;
      }
      return;
    }

    // For other steps, use the form data from the event
    const formData = event.detail.data;
    const stepHandler =
      this.signupFlow.steps[this.signupFlow.state.currentStep];

    try {
      // Rest of the handler for other steps...
      // ... existing code for other form steps ...
    } catch (error) {
      console.error("Form submission error:", error);
      this.signupFlow.showError("An unexpected error occurred");
    }
  }

  async handleInviteFound(form, emailInput) {
    const handler = formStates["invite-found"];
    const nextStepConfig = formSteps["verify-email"];
    if (!handler || !nextStepConfig) return;

    // Store the email we'll need later
    const userEmail = this.signupFlow.state.formData.email || emailInput.value;
    console.log("Email to be used:", userEmail);

    // Fade out current form content
    const currentSlide = form.element.closest(".form-slide");
    if (currentSlide) {
      currentSlide.style.opacity = "0";
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    // Get template content for verify email
    const templates = document.getElementById("formTemplates");
    const verifyEmailTemplate = templates?.content.querySelector(
      `#${nextStepConfig.inputTemplateId}`
    );

    if (verifyEmailTemplate && currentSlide) {
      // 1. Update form structure first
      const formContent = currentSlide.querySelector(".form-content");
      if (formContent) {
        formContent.innerHTML = "";
        formContent.appendChild(verifyEmailTemplate.cloneNode(true));
      }

      // 2. Update form attributes
      const formElement = currentSlide.querySelector("form");
      if (formElement) {
        formElement.id = nextStepConfig.formId;
        formElement.classList.remove("submitting", "valid", "invalid");
        const submitButton = formElement.querySelector('button[type="submit"]');
        if (submitButton && nextStepConfig.states?.initial?.button) {
          submitButton.textContent = nextStepConfig.states.initial.button.text;
          submitButton.dataset.textval =
            nextStepConfig.states.initial.button.text;
        }
      }

      // 3. Update data-step attribute
      currentSlide.dataset.step = "verify-email";

      // 4. Update header content
      const headerContent = currentSlide.querySelector(".header-content");
      if (headerContent) {
        const heading = headerContent.querySelector("h2");
        const instruction = headerContent.querySelector(".form-instruction");

        const initialState = nextStepConfig.states?.initial || {};
        if (heading) {
          heading.textContent = initialState.title || handler.heading;
        }
        if (instruction) {
          instruction.innerHTML =
            initialState.instruction || handler.instruction;
        }
      }

      // 5. Update stage blocks and text
      if (this.signupFlow.transitionHandler) {
        const emailStageBlock = document.querySelector(
          ".stage-block.stage-email"
        );
        if (emailStageBlock) {
          const actionEl = emailStageBlock.querySelector(".action");
          if (actionEl) {
            actionEl.textContent =
              nextStepConfig.states?.initial?.action || "verify email";
          }
        }
        await this.signupFlow.transitionHandler.updateStageBlocks(
          "verify-email"
        );
      }

      // 6. Handle next step transition
      if (handler.nextStep) {
        this.signupFlow.state.currentStep = handler.nextStep;
        this.signupFlow.domCache.initCache();
        await this.signupFlow.updateUI();
      }

      // 7. Now that DOM is fully updated, set the email
      console.log("Setting email in instruction...");
      const sentEmailEl = currentSlide.querySelector(".sent-email");
      if (sentEmailEl) {
        console.log("Found .sent-email element, setting to:", userEmail);
        sentEmailEl.textContent = userEmail;
      } else {
        console.log("Could not find .sent-email element");
      }

      // 8. Finally fade in
      currentSlide.style.opacity = "1";
    }
  }

  async handleInviteNotFound(form, emailInput) {
    const handler = formStates["invite-not-found"];
    if (!handler) return;

    // Update heading and instruction
    if (handler.heading) {
      const heading = form.element.querySelector("h2");
      if (heading) heading.textContent = handler.heading;
    }
    if (handler.instruction) {
      const instruction = form.element.querySelector(".form-instruction");
      if (instruction) instruction.innerHTML = handler.instruction;
    }

    // Update input state
    if (handler.inputState && emailInput) {
      const control = emailInput.closest(".input-control");
      if (control) {
        control.classList.remove("valid", "invalid");
        control.classList.add(handler.inputState);
      }
    }

    // Handle form enable/disable
    if (handler.enableForm !== undefined) {
      const submitButton = form.element.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.disabled = !handler.enableForm;
        submitButton.style.pointerEvents = handler.enableForm ? "auto" : "none";
      }
      if (emailInput) {
        emailInput.disabled = !handler.enableForm;
      }
    }

    // Handle class changes
    if (handler.removeClasses) {
      handler.removeClasses.forEach((className) => {
        form.element.classList.remove(className);
        document.body.classList.remove(className);
      });
    }
    if (handler.addClasses) {
      handler.addClasses.forEach((className) => {
        form.element.classList.add(className);
      });
    }
  }
}
