/**
 * Legal Documents Configuration
 * @module modules/legal/config
 */

export const DOCUMENT_TYPES = {
  TERMS: "terms",
  PRIVACY: "privacy",
  COOKIES: "cookies",
};

export const STORAGE_PATHS = {
  PDF: "legal",
  MARKDOWN: "docs/legal",
};

export const FILE_EXTENSIONS = {
  PDF: ".pdf",
  MARKDOWN: ".md",
  HTML: ".html",
};

export const UI_CONFIG = {
  ANIMATION_DURATION: 300,
  DEFAULT_DOCUMENT: DOCUMENT_TYPES.TERMS,
};

export const NOTIFICATION_CONFIG = {
  ENABLED: true,
  DEBOUNCE_MS: 1000 * 60 * 5, // 5 minutes
};
