// Manages form state and updates
import { formSteps } from "../config/formSteps.js";

export class StateManager {
  constructor(context) {
    this.context = context;
  }

  updateFormState(step, state = "initial") {
    const config = formSteps[step];
    if (!config || !config.states || !config.states[state]) return;

    const stateConfig = config.states[state];
    const form = document.querySelector(`#${config.formId}`);
    if (!form) return;

    // Update header content if it exists
    const headerContent = form
      .closest(".form-slide")
      ?.querySelector(".header-content");
    if (headerContent) {
      const titleEl = headerContent.querySelector("h2");
      const instructionEl = headerContent.querySelector(".form-instruction");

      if (titleEl && stateConfig.title) {
        titleEl.textContent = stateConfig.title;
      }
      if (instructionEl && stateConfig.instruction) {
        instructionEl.innerHTML = stateConfig.instruction;
      }
    }

    // Update button text if it exists
    if (stateConfig.button) {
      const button = form.querySelector(".btn.submit");
      const submitting = form.querySelector(".form-sumbitting span");

      if (button && stateConfig.button.text) {
        button.textContent = stateConfig.button.text;
        button.dataset.textval = stateConfig.button.text;
      }
      if (submitting && stateConfig.button.submittingText) {
        submitting.textContent = stateConfig.button.submittingText;
      }
    }

    // Update stage block action text if it exists
    if (stateConfig.action) {
      const stageBlock = document.querySelector(".stage-block.active");
      const actionEl = stageBlock?.querySelector(".action");
      if (actionEl) {
        actionEl.textContent = stateConfig.action;
      }
    }
  }

  showError(message) {
    const currentForm = document.querySelector(
      `.fs-form[data-step="${this.context.state.currentStep}"]`
    );
    if (!currentForm) return;

    const errorContainer =
      currentForm.querySelector(".form-error") || document.createElement("div");
    errorContainer.className = "form-error";
    errorContainer.textContent = message;

    // Insert after the header if it exists, otherwise at the start of the form
    const header = currentForm.querySelector(".header-content");
    if (header) {
      header.after(errorContainer);
    } else {
      currentForm.prepend(errorContainer);
    }
  }

  showConfirmationField() {
    const confirmField = document.getElementById("confirmEmailAddress");
    const confirmError = document.querySelector(".email-match-error");

    if (confirmField && confirmError) {
      confirmField.style.display = "block";
      confirmField.required = true;

      // Update the instruction text
      const instruction = document.querySelector(".form-instruction");
      if (instruction) {
        instruction.innerHTML =
          "Please confirm your email address to continue.";
      }
    }
  }
}
