// Form state configurations
export const formStates = {
  "invite-found": {
    heading: "Congratulations! Your email was found.",
    instruction:
      "We've sent a verification code to your email. Please enter the 6-digit code to confirm your email address. If you don't see it, check your spam folder.",
    actionText: "validate email",
    inputState: "valid",
    enableForm: false,
    removeClasses: ["submitting", "loading"],
    addClasses: ["valid"],
    nextStep: "verify-email",
  },
  "invite-not-found": {
    heading: "Your email was not found in the invitation list.",
    instruction:
      "Please confirm your email and try again, or click <a href='/waitlist'>here</a> to join the waitlist.",
    actionText: "enter email",
    inputState: "invalid",
    enableForm: true,
    removeClasses: ["submitting", "loading"],
    addClasses: ["invalid"],
  },
};
