// Form state configurations for different scenarios
export const formStates = {
  "invite-not-found": {
    heading: "Your email was not found in the invitation list.",
    instruction:
      "Please confirm your email and try again, or click <a href='/waitlist'>here</a> to join the waitlist.",
    actionText: "enter email",
    inputState: "invalid",
    enableForm: true,
    removeClasses: ["submitting", "loading"],
    addClasses: ["invalid"],
  },
  "invite-found": {
    heading: "Congratulations! Your email was found.",
    instruction:
      "We've sent a verification code to your email. Please enter the 6-digit code to confirm your email address. If you don't see it, check your spam folder.",
    actionText: "validate email",
    inputState: "valid",
    enableForm: false,
    removeClasses: ["submitting", "loading"],
    addClasses: ["valid"],
    nextStep: "verify-email",
  },
};

// Step validation configurations
export const stepValidation = {
  "enter-email": {
    validate: async (formData, context) => {
      try {
        context.emailAttempts++;
        const requireConfirmation = context.emailAttempts > 2;

        if (requireConfirmation) {
          if (!formData.confirmEmailAddress) {
            context.updateFormState("enter-email", "confirming");
            return {
              isValid: false,
              error: "Please confirm your email address",
            };
          }
          if (formData.emailAddress !== formData.confirmEmailAddress) {
            context.updateFormState("enter-email", "confirming");
            return {
              isValid: false,
              error: "Email addresses do not match",
            };
          }
        }

        const response = await fetch("/app/invites/check", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: formData.emailAddress,
          }),
        });
        const data = await response.json();

        if (data.hasInvite) {
          context.startResendTimer();
        }

        return {
          isValid: data.hasInvite,
          data: { ...data.invite, email: formData.emailAddress },
          nextStep: data.hasInvite ? "verify-email" : "waitlist",
        };
      } catch (error) {
        console.error("Email validation error:", error);
        return { isValid: false, error };
      }
    },
  },
  "verify-email": {
    validate: async (formData, context) => {
      try {
        const response = await fetch("/app/verify/email", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            code: formData.verifyEmail,
            email: context.formData.email,
          }),
        });
        const data = await response.json();
        return {
          isValid: data.success,
          data: { emailVerified: data.success },
          nextStep: "enter-phone",
        };
      } catch (error) {
        return { isValid: false, error };
      }
    },
  },
  "enter-phone": {
    validate: async (formData) => {
      try {
        const response = await fetch("/app/verify/phone/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ phone: formData.cellPhone }),
        });
        const data = await response.json();
        return {
          isValid: data.success,
          data: { phone: formData.cellPhone },
          nextStep: "verify-phone",
        };
      } catch (error) {
        return { isValid: false, error };
      }
    },
  },
  "verify-phone": {
    validate: async (formData, context) => {
      try {
        const response = await fetch("/app/verify/phone", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            code: formData.verifyPhone,
            phone: context.formData.phone,
          }),
        });
        const data = await response.json();
        return {
          isValid: data.verified,
          data: { phoneVerified: true },
          nextStep: "enter-name",
        };
      } catch (error) {
        return { isValid: false, error };
      }
    },
  },
  "enter-name": {
    validate: async (formData) => {
      return {
        isValid: true,
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
        nextStep: "set-password",
      };
    },
  },
  "set-password": {
    validate: async (formData, context) => {
      if (formData.password !== formData.passConfirm) {
        return { isValid: false, error: "Passwords do not match" };
      }
      try {
        const response = await fetch("/app/signup/complete", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...context.formData,
            password: formData.password,
          }),
        });
        const data = await response.json();
        return {
          isValid: data.success,
          data: { accountCreated: true },
          nextStep: "complete",
        };
      } catch (error) {
        return { isValid: false, error };
      }
    },
  },
};
