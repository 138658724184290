// Handles form transitions and animations
import { formSteps } from "../config/formSteps.js";

export class TransitionHandler {
  constructor(context) {
    this.context = context;
  }

  async animateTransition(container, nextStep) {
    // Get current and next slides
    const currentSlide = container.querySelector(".form-slide.active");
    const nextSlide = container.querySelector(
      `.form-slide[data-step="${nextStep}"]`
    );
    const config = formSteps[nextStep];

    if (!nextSlide || !config) {
      console.error(`Next slide not found for step: ${nextStep}`);
      return;
    }

    // Update stage blocks first
    await this.updateStageBlocks(nextStep);

    // Fade out current slide
    if (currentSlide) {
      currentSlide.style.opacity = "0";
      currentSlide.classList.remove("active");
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    // Update next slide content before showing it
    const headerContent = nextSlide.querySelector(".header-content");
    if (headerContent && config.states?.initial) {
      const title = headerContent.querySelector("h2");
      const instruction = headerContent.querySelector(".form-instruction");

      if (title) title.textContent = config.states.initial.title;
      if (instruction)
        instruction.innerHTML = config.states.initial.instruction;
    }

    // Get the form content where inputs will go
    const formContent = nextSlide.querySelector(".form-content");
    if (formContent && config) {
      // Get and clone the appropriate input template
      const templates = document.getElementById("formTemplates");
      const inputTemplate = templates?.content.querySelector(
        `#${config.inputTemplateId}`
      );

      if (inputTemplate) {
        formContent.innerHTML = "";
        formContent.appendChild(inputTemplate.cloneNode(true));
      }
    }

    // Update button text if needed
    const submitButton = nextSlide.querySelector(".btn.submit");
    const submittingText = nextSlide.querySelector(".form-sumbitting span");

    if (submitButton && config.states?.initial?.button?.text) {
      submitButton.textContent = config.states.initial.button.text;
      submitButton.dataset.textval = config.states.initial.button.text;
    }

    if (submittingText && config.states?.initial?.button?.submittingText) {
      submittingText.textContent = config.states.initial.button.submittingText;
    }

    // Show next slide
    nextSlide.classList.add("active");
    // Force a reflow before starting the animation
    nextSlide.offsetHeight;
    nextSlide.style.opacity = "1";

    // Set initial form state
    this.context.updateFormState(nextStep, "initial");

    // Wait for the DOM to settle before initializing the form
    await new Promise((resolve) => setTimeout(resolve, 50));
    await this.context.initializeFormValidation();
  }

  async updateStageBlocks(currentStep) {
    const stageMap = {
      "enter-email": "email",
      "verify-email": "email",
      "enter-phone": "phone",
      "verify-phone": "phone",
      "enter-name": "info",
      "set-password": "pass",
    };

    const currentStage = stageMap[currentStep];
    if (!currentStage) return;

    // Get all stage blocks except the main block
    const stageBlocks = document.querySelectorAll(
      ".signup-stage-blocks .stage-block:not(.stage-block-main)"
    );
    if (!stageBlocks.length) return;

    // Define the progression stages (excluding main)
    const stages = ["email", "phone", "info", "pass"];
    const currentIndex = stages.indexOf(currentStage);

    // Update stage block states
    stageBlocks.forEach((block) => {
      // Skip the main block
      if (block.classList.contains("stage-block-main")) return;

      // Update email stage action text
      if (block.classList.contains("stage-email")) {
        const actionEl = block.querySelector(".action");
        if (actionEl) {
          actionEl.textContent =
            currentStep === "verify-email" ? "verify email" : "enter email";
        }
      }

      // Only mark previous stages as done if we're past email verification
      if (this.context.state.formData.emailVerified) {
        const blockStage = stages.find((stage) =>
          block.classList.contains(`stage-${stage}`)
        );
        const blockIndex = stages.indexOf(blockStage);

        if (blockIndex < currentIndex) {
          block.classList.add("done");
        }
      }
    });

    // Update active states
    stageBlocks.forEach((block) => {
      if (block.classList.contains(`stage-${currentStage}`)) {
        block.classList.add("active");
      } else {
        block.classList.remove("active");
      }
    });
  }
}
