// Modern signup flow management
import { DOMCache } from "../../utility/dom.js";
import signupTemplate from "../../../inc/signup.html";

// Import the Firesite Form Library
import {
  FiresiteForm,
  FormInput,
  FormValidation,
} from "../../lib/forms/index.js";

// Import modular modules
import { formSteps } from "./config/formSteps.js";
import { TransitionHandler } from "./handlers/transitions.js";
import { FormSubmitHandler } from "./handlers/formSubmit.js";
import { StateManager } from "./utils/stateManager.js";

export class SignupFlow {
  constructor() {
    this.state = {
      currentStep: "enter-email",
      formData: {},
      validationState: {},
      isProcessing: false,
      emailAttempts: 0,
    };

    this.domCache = new DOMCache();
    this.forms = new Map();
    this.templateCache = null;

    // Initialize handlers
    this.transitionHandler = new TransitionHandler(this);
    this.formSubmitHandler = new FormSubmitHandler(this);
    this.stateManager = new StateManager(this);

    // Add step to ID mapping
    this.stepToId = {
      "enter-email": "emailForm",
      "verify-email": "verifyEmailForm",
      "enter-phone": "phoneForm",
      "verify-phone": "verifyPhoneForm",
      "enter-name": "nameForm",
      "set-password": "passwordForm",
    };
  }

  async init() {
    console.log("Initializing SignupFlow");
    // Initialize DOM cache
    this.domCache.initCache();

    // Get the container
    const container = document.getElementById("betaSignup");
    if (!container) {
      console.error("Signup container not found");
      return;
    }

    // Parse and inject the signup template
    container.innerHTML = signupTemplate;

    // Force DOM cache refresh after template injection
    this.domCache.initCache();

    // Get the form-slides container
    const slidesContainer = container.querySelector(".form-slides");
    if (slidesContainer) {
      // Set initial styles
      slidesContainer.style.opacity = "0";
      slidesContainer.style.visibility = "visible";
      slidesContainer.style.transition = "opacity 0.3s ease-in-out";

      // Get the initial slide and add active class
      const initialSlide = slidesContainer.querySelector(
        '.form-slide[data-step="enter-email"]'
      );
      if (initialSlide) {
        initialSlide.classList.add("active");
      }

      // Build and inject the initial form content
      await this.updateUI();

      // Initialize the form validation first
      await this.initializeFormValidation();

      // Set up form submission handlers and observers
      this.setupFormSubmissionHandlers();

      // Make the form visible with a slight delay for animation
      await new Promise((resolve) => setTimeout(resolve, 50));
      slidesContainer.style.opacity = "1";
    }

    console.log("SignupFlow initialization complete", this);
  }

  async updateUI() {
    const { currentStep } = this.state;
    const config = formSteps[currentStep];

    if (!config) return;

    // Update stage blocks first
    await this.transitionHandler.updateStageBlocks(currentStep);

    // Get the form slides container and current slide
    const slidesContainer = document.querySelector(".form-slides");
    if (!slidesContainer) return;

    const currentSlide = slidesContainer.querySelector(
      `.form-slide[data-step="${currentStep}"]`
    );
    if (!currentSlide) return;

    // Update form state
    this.stateManager.updateFormState(currentStep, "initial");

    // Get the current form
    const form = currentSlide.querySelector(`#${config.formId}`);
    if (!form) return;

    // Get the form content div where inputs will go
    const formContent = form.querySelector(".form-content");
    if (!formContent) return;

    // Get template content
    const templates = document.getElementById("formTemplates");
    if (!templates) {
      console.error("Form templates not found");
      return;
    }

    // Find and clone the appropriate input template
    const inputTemplate = templates.content.querySelector(
      `#${config.inputTemplateId}`
    );
    if (!inputTemplate) {
      console.error(`Input template not found: ${config.inputTemplateId}`);
      return;
    }

    // Clear existing content and add new template
    formContent.innerHTML = "";
    formContent.appendChild(inputTemplate.cloneNode(true));

    // Update form values if they exist
    Object.entries(this.state.formData).forEach(([key, value]) => {
      const input = form.querySelector(`[name="${key}"]`);
      if (input) input.value = value;
    });

    // Force DOM cache refresh
    this.domCache.initCache();

    // Ensure the container is visible
    slidesContainer.style.opacity = "1";
    slidesContainer.style.visibility = "visible";
  }

  setupFormSubmissionHandlers() {
    console.log("Setting up form submission handlers");

    // Get the current form instance
    const currentForm = this.forms.get(this.state.currentStep);
    if (!currentForm) {
      console.error("No form instance found for step:", this.state.currentStep);
      return;
    }

    // Listen for FiresiteForm's submit event
    currentForm.element.addEventListener("fs:submit", async (event) => {
      await this.formSubmitHandler.handleSubmit(event, currentForm);
    });

    // Input focus handling
    document.addEventListener(
      "focus",
      (e) => {
        const input = e.target.closest(".fs-input");
        if (!input) return;

        const control = input.closest(".input-control");
        if (control) {
          control.classList.add("focused");
          // Remove readonly set by autocomplete prevention
          if (input.readOnly && !input.classList.contains("readonly")) {
            input.readOnly = false;
          }
        }
      },
      true
    );

    // Input change handling for dirty state
    document.addEventListener(
      "input",
      (e) => {
        const input = e.target.closest(".fs-input");
        if (!input) return;

        // Update state when input becomes dirty
        if (
          input.value.length > 0 &&
          this.state.currentStep === "enter-email"
        ) {
          this.stateManager.updateFormState("enter-email", "dirty");
        } else if (
          input.value.length === 0 &&
          this.state.currentStep === "enter-email"
        ) {
          this.stateManager.updateFormState("enter-email", "initial");
        }
      },
      true
    );

    // Watch for validation class changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const control = mutation.target;
          if (!control.classList.contains("input-control")) return;

          const input = control.querySelector(".fs-input");
          if (!input || this.state.currentStep !== "enter-email") return;

          if (control.classList.contains("valid")) {
            this.stateManager.updateFormState("enter-email", "valid");
          } else if (control.classList.contains("invalid")) {
            this.stateManager.updateFormState("enter-email", "dirty");
          }
        }
      });
    });

    // Observe all input controls
    document.querySelectorAll(".input-control").forEach((control) => {
      observer.observe(control, { attributes: true });
    });

    // Input blur handling
    document.addEventListener(
      "blur",
      (e) => {
        const input = e.target.closest(".fs-input");
        if (!input) return;

        const control = input.closest(".input-control");
        if (control) {
          control.classList.remove("focused");
        }
      },
      true
    );
  }

  async initializeFormValidation() {
    console.log("Starting form validation initialization");

    // Get form by ID since we know the mapping
    const formId = this.stepToId[this.state.currentStep];
    const currentForm = document.getElementById(formId);

    console.log("Looking for form with ID:", formId);
    if (!currentForm) {
      console.warn("Form not found for ID:", formId);
      console.log(
        "Current forms in DOM:",
        document.querySelectorAll(".fs-form")
      );
      return;
    }

    console.log("Found form:", currentForm);

    // Clear any existing form instance
    const existingForm = this.forms.get(this.state.currentStep);
    if (existingForm) {
      console.log("Cleaning up existing form instance");
      existingForm.destroy?.();
      this.forms.delete(this.state.currentStep);
    }

    // Initialize new FiresiteForm instance
    console.log("Creating new FiresiteForm instance");
    const form = new FiresiteForm(currentForm);
    this.forms.set(this.state.currentStep, form);

    console.log("Calling form.init()");
    form.init();
    console.log("Form initialization complete");

    // Initialize special inputs after form initialization
    const inputs = currentForm.querySelectorAll(".fs-input");
    console.log("Found inputs:", inputs.length);

    inputs.forEach((input) => {
      // Special handling for phone input
      if (input.id === "cellPhone") {
        const formInput = new FormInput(input);
        formInput.setMask("+1 (###) ###-####");
        formInput.addFormatter((value) => {
          return value
            .replace(/\D/g, "")
            .replace(/^(\d{3})/, "($1) ")
            .replace(/(\d{3})(\d{4})/, "$1-$2");
        });
      }
    });

    // Hide confirmation field initially
    const confirmField = document.getElementById("confirmEmailAddress");
    if (confirmField) {
      confirmField.style.display = "none";
      confirmField.required = false;
    }
  }

  // Proxy methods to maintain API compatibility
  updateFormState(...args) {
    return this.stateManager.updateFormState(...args);
  }

  showError(...args) {
    return this.stateManager.showError(...args);
  }

  showConfirmationField(...args) {
    return this.stateManager.showConfirmationField(...args);
  }

  startResendTimer() {
    const resendContainer = document.querySelector(".resend-container");
    const resendButton = document.querySelector(".resend-button");
    const countdownSpan = document.querySelector(".countdown");
    const attemptsCount = document.querySelector(".attempts-count");

    if (!resendContainer || !resendButton || !countdownSpan) return;

    // Show the resend container
    resendContainer.style.display = "block";

    // Set initial state
    let timeLeft = 60;
    resendButton.disabled = true;

    // Update attempts count
    if (attemptsCount && this.state.formData.resendAttempts !== undefined) {
      const remaining = 3 - this.state.formData.resendAttempts;
      attemptsCount.textContent = remaining;
    }

    // Start countdown
    const timer = setInterval(() => {
      timeLeft--;
      countdownSpan.textContent = timeLeft;

      if (timeLeft <= 0) {
        clearInterval(timer);
        resendButton.disabled = false;
        resendButton.textContent = "Resend Code";
      }
    }, 1000);

    // Handle resend click
    resendButton.onclick = async () => {
      try {
        resendButton.disabled = true;
        const response = await fetch("/app/resend-otp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: this.state.formData.email,
          }),
        });

        const data = await response.json();

        if (data.success) {
          // Update attempts count
          if (attemptsCount) {
            const remaining = 3 - data.resendAttempts;
            attemptsCount.textContent = remaining;
          }

          // Restart timer
          timeLeft = 60;
          this.startResendTimer();
        } else {
          this.showError(data.message || "Failed to resend code");
        }
      } catch (error) {
        console.error("Resend error:", error);
        this.showError("Failed to resend verification code");
      }
    };
  }
}
