/**
 * @author Thomas S. Butler
 * @abstract Enhanced input component handling form input events
 * @copyright 2022-2024 Firesite LLC
 */

import { AutofillPrevention } from "../utils/autofill.js";

export class FormInput {
  constructor(element, options = {}) {
    this.element = element;
    this.options = {
      autoComplete: "off",
      floatingLabel: true,
      mask: null,
      formatters: [],
      ...options,
    };

    // Bind event handlers
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusIn = this.handleFocusIn.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleCut = this.handleCut.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    // Initialize only if element is properly in DOM
    if (this.verifyDOMPresence()) {
      this.initialize();
    }
  }

  /**
   * Verify element is properly in DOM
   */
  verifyDOMPresence() {
    return this.element && this.element.parentNode;
  }

  /**
   * Initialize the input following strict sequence
   */
  initialize() {
    console.log("Initializing FormInput");
    // 1. Basic element initialization
    this.initializeElement();

    // 2. Setup name scoping
    this.setupNameScoping();

    // 3. Setup type scoping
    this.setupTypeScoping();

    // 4. Setup dataset attributes
    this.setupDatasetAttributes();

    // 5. Check dirty states
    this.checkInitialDirtyState();

    // 6. Setup autofill prevention
    this.setupAutofillPrevention();

    // 7. Attach event listeners in sequence
    this.attachEventListeners();

    // Mark as ready
    this.element.classList.add("fs-initialized");
    console.log("FormInput initialized");
  }

  /**
   * Initialize basic element properties
   */
  initializeElement() {
    // Basic class setup
    this.element.classList.add("fs-input");

    // Setup control container if needed
    const control = this.element.closest(".input-control");
    if (!control) return;

    // Setup floating label if enabled
    if (this.options.floatingLabel) {
      const label = control.querySelector("label");
      if (label) {
        label.classList.add("fs-floating-label");
        if (this.element.value) {
          control.classList.add("has-value");
        }
      }
    }
  }

  /**
   * Setup name scoping
   */
  setupNameScoping() {
    // Store original name if present
    const name = this.element.getAttribute("name");
    this.element.dataset.inputname = name || "";
  }

  /**
   * Setup type scoping
   */
  setupTypeScoping() {
    // Store original type if present
    const type = this.element.getAttribute("type");
    this.element.dataset.inputtype = type || "";
  }

  /**
   * Setup dataset attributes
   */
  setupDatasetAttributes() {
    // Ensure all required datasets are present
    const requiredDatasets = ["inputname", "inputtype"];
    requiredDatasets.forEach((dataset) => {
      if (!this.element.dataset[dataset]) {
        this.element.dataset[dataset] = "";
      }
    });
  }

  /**
   * Check initial dirty state
   */
  checkInitialDirtyState() {
    const control = this.element.closest(".input-control");
    if (!control) return;

    if (this.element.value.trim()) {
      control.classList.add("dirty");
    }

    // Handle Chrome autofill
    if (this.isWebkit() && this.element.matches(":-webkit-autofill")) {
      control.classList.add("dirty");
    }
  }

  /**
   * Check if browser is webkit based
   */
  isWebkit() {
    return (
      typeof window !== "undefined" &&
      "WebkitAppearance" in document.documentElement.style
    );
  }

  /**
   * Setup autofill prevention
   */
  setupAutofillPrevention() {
    // Use the AutofillPrevention utility
    AutofillPrevention.apply(this.element);
  }

  /**
   * Attach event listeners in proper sequence
   */
  attachEventListeners() {
    console.log("Attaching event listeners");
    // 1. Primary Events
    this.attachPrimaryEvents();

    // 2. Secondary Events
    this.attachSecondaryEvents();

    // 3. Clipboard Events
    this.attachClipboardEvents();

    // 4. Focus Events
    this.attachFocusEvents();

    // 5. Input Events
    this.attachInputEvents();
    console.log("Event listeners attached");
  }

  /**
   * Attach primary event listeners
   */
  attachPrimaryEvents() {
    // Remove keyup from here since it's in attachInputEvents
    this.element.addEventListener("blur", this.handleBlur);
    this.element.addEventListener("focusout", this.handleFocusOut);
  }

  /**
   * Attach secondary event listeners
   */
  attachSecondaryEvents() {
    this.element.addEventListener("change", this.handleChange);
    this.element.addEventListener("input", this.handleInput);
    this.element.addEventListener("keydown", this.handleKeyDown);
  }

  /**
   * Attach clipboard event listeners
   */
  attachClipboardEvents() {
    this.element.addEventListener("paste", this.handlePaste);
    this.element.addEventListener("cut", this.handleCut);
    this.element.addEventListener("copy", this.handleCopy);
    this.element.addEventListener("drop", this.handleDrop);
  }

  /**
   * Attach focus event listeners
   */
  attachFocusEvents() {
    this.element.addEventListener("focus", this.handleFocus);
    this.element.addEventListener("blur", this.handleBlur);
    this.element.addEventListener("focusin", this.handleFocusIn);
  }

  /**
   * Attach input event listeners
   */
  attachInputEvents() {
    console.log("Attaching input events");
    this.element.addEventListener("keyup", this.handleKeyUp);
    this.element.addEventListener("keydown", this.handleKeyDown);
    this.element.addEventListener("keypress", this.handleKeyPress);
    this.element.addEventListener("input", this.handleInput);
    console.log("Input events attached");
  }

  /**
   * Capitalize first letter of each word
   */
  capitalizeEntry(str) {
    const words = str.split(" ");
    const ignoreWords = ["in", "of", "the", "at", "a", "my", "and", "to"];

    const capitalizedWords = words.map((word, index) => {
      if (ignoreWords.includes(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join(" ");
  }

  /**
   * Remove special characters from text
   */
  killSpecialCharacters(value) {
    return value.replace(/[^a-zA-Z0-9\s$]+/g, "");
  }

  /**
   * Remove spaces from text
   */
  killEmptySpaces(value) {
    return value.replace(/\s/g, "");
  }

  /**
   * Sanitize input value
   */
  sanitizeInput(value) {
    if (value.length > 1200) {
      value = value.substr(0, 1199);
    }

    // Remove HTML tags if present
    if (value.match(/(<([^>]+)>)/gi)) {
      value = this.stripHTML(value);
    }

    return value;
  }

  /**
   * Strip HTML tags from text
   */
  stripHTML(value) {
    let buffer = value;
    // Remove all HTML tags and their contents
    buffer = buffer.replace(/<[^>]*>.*?<\/[^>]*>/g, "");
    // Remove any remaining tags
    buffer = buffer.replace(/<[^>]*>/g, "");
    return buffer;
  }

  // Event handlers
  handleKeyUp(e) {
    // console.log("handleKeyUp called", e.key, e.type);
    // Handle name class (capitalize first letter)
    if (
      this.element.classList.contains("name") &&
      this.element.value.length === 1
    ) {
      const char = String.fromCharCode(e.keyCode || e.which);
      if (char.match(/^\w$/)) {
        this.element.value =
          this.element.value.charAt(0).toUpperCase() +
          this.element.value.slice(1);
        return;
      }
    }

    // Handle upper class (all uppercase)
    if (this.element.classList.contains("upper")) {
      this.element.value = this.element.value.toUpperCase();
    }

    // Handle max length
    if (this.element.hasAttribute("max")) {
      const max = parseInt(this.element.getAttribute("max"));
      if (this.element.value.length > max) {
        this.element.value = this.element.value.slice(0, max);
      }
    }

    // Remove spaces from email inputs
    if (this.element.type === "email" && this.element.value.includes(" ")) {
      this.element.value = this.element.value.replace(/\s/g, "");
    }

    // Handle email validation
    if (this.element.type === "email") {
      // console.log("Email validation running");
      const control = this.element.closest(".input-control");
      if (!control) {
        console.log("No control found");
        return;
      }

      const value = this.element.value.trim();
      console.log("Email value:", value);
      if (value && !this.isValidEmailAddress(value)) {
        control.classList.add("invalid");
        control.classList.remove("valid");
      } else if (value) {
        control.classList.add("valid");
        control.classList.remove("invalid");
      }
    }
  }

  handleBlur(e) {
    // Reset name to original (handled by AutofillPrevention)
    if (
      !this.element.classList.contains("selectbox") &&
      !this.element.classList.contains("textbox")
    ) {
      // Restore the original name from data attribute
      this.element.name = this.element.dataset.originalName;
      this.element.readOnly = true;
    }

    // Handle noun class (capitalize words)
    if (this.element.classList.contains("noun") && this.element.value.trim()) {
      this.element.value = this.capitalizeEntry(this.element.value);
    }

    // Handle email type
    if (this.element.type === "email" && this.element.value.trim()) {
      const fixedEmail = this.emailCaseFix(this.element.value);
      if (fixedEmail !== this.element.value) {
        this.element.value = fixedEmail;
      }
    }

    // Sanitize input unless explicitly allowed
    if (!this.element.hasAttribute("special-chars-allowed")) {
      this.element.value = this.sanitizeInput(this.element.value);
    }

    // Remove active class from control
    const control = this.element.closest(".input-control");
    if (control) {
      control.classList.remove("active");
    }
  }

  handleFocusOut(e) {
    this.handleBlur(e);
  }

  handleChange(e) {
    // Handle noun class (capitalize words)
    if (this.element.classList.contains("noun") && this.element.value.trim()) {
      this.element.value = this.capitalizeEntry(this.element.value);
    }

    // Sanitize input unless explicitly allowed
    if (!this.element.hasAttribute("special-chars-allowed")) {
      this.element.value = this.sanitizeInput(this.element.value);
    }
  }

  handleInput(e) {
    // Handle max length
    if (this.element.hasAttribute("max")) {
      const max = parseInt(this.element.getAttribute("max"));
      if (this.element.value.length > max) {
        this.element.value = this.element.value.slice(0, max);
      }
    }

    // Handle onlytext class
    if (this.element.classList.contains("onlytext")) {
      this.element.value = this.killSpecialCharacters(this.element.value);
    }

    // Handle nospace class
    if (this.element.classList.contains("nospace")) {
      this.element.value = this.killEmptySpaces(this.element.value);
    }

    // Handle email input sanitization
    if (this.element.type === "email" && this.element.value) {
      // Remove spaces
      let sanitized = this.element.value.replace(/\s/g, "");

      // Split into parts
      const parts = sanitized.split("@");

      // Handle local part
      if (parts[0]) {
        // Limit length to 64
        parts[0] = parts[0].slice(0, 64);
        // Allow alphanumeric and ._+-, remove consecutive special chars
        parts[0] = parts[0]
          .replace(/[^a-zA-Z0-9._+-]/g, "")
          .replace(/[._+-]{2,}/g, (m) => m[0]);
      }

      // Handle domain part
      if (parts[1]) {
        // Limit length to 64
        parts[1] = parts[1].slice(0, 64);
        // Convert to lowercase, allow alphanumeric/dots/hyphens, remove consecutive special chars
        parts[1] = parts[1]
          .toLowerCase()
          .replace(/[^a-z0-9.-]/g, "")
          .replace(/[.-]{2,}/g, (m) => m[0]);
      }

      sanitized = parts.join("@");

      // Update value if changed
      if (sanitized !== this.element.value) {
        this.element.value = sanitized;
      }
    }

    // Reset name to original to prevent autofill
    if (
      !this.element.classList.contains("selectbox") &&
      !this.element.classList.contains("textbox")
    ) {
      this.element.name = this.element.dataset.inputname;
    }

    // Update dirty state
    const control = this.element.closest(".input-control");
    if (control) {
      if (this.element.value.trim()) {
        control.classList.add("dirty");
      } else {
        control.classList.remove("dirty");
      }

      // Handle email validation
      if (this.element.type === "email" && this.element.value.trim()) {
        if (!this.isValidEmailAddress(this.element.value)) {
          control.classList.add("invalid");
        } else {
          control.classList.remove("invalid");
        }
      }
    }
  }

  handleFocus(e) {
    // Enable editing (readOnly is handled by AutofillPrevention)
    this.element.readOnly = false;

    // Add active class to control
    const control = this.element.closest(".input-control");
    if (control) {
      control.classList.add("active");
    }
  }

  handleFocusIn(e) {
    this.handleFocus(e);
  }

  /**
   * Email validation
   */
  isValidEmailAddress(email) {
    // Basic format check
    if (!email || !email.includes("@")) {
      return false;
    }

    // Split and check parts
    const [localPart, domain] = email.split("@");
    if (!localPart || !domain) {
      return false;
    }

    // Check length limits
    if (localPart.length > 64 || domain.length > 64) {
      return false;
    }

    // Check domain parts
    const domainParts = domain.split(".");
    if (domainParts.length < 2) {
      return false;
    }

    // Check TLD length (must be at least 2 characters)
    const tld = domainParts[domainParts.length - 1];
    if (tld.length < 2) {
      return false;
    }

    // Additional domain validations
    if (
      domain.startsWith(".") ||
      domain.endsWith(".") ||
      domain.includes("..")
    ) {
      return false;
    }

    return true;
  }

  /**
   * Email case fix
   */
  emailCaseFix(email) {
    // If it's not a valid email format, return as is
    if (!email.includes("@")) {
      return email;
    }

    const [localPart, domain] = email.split("@");

    // Always convert domain to lowercase, preserve local part case
    return localPart + "@" + domain.toLowerCase();
  }

  // Other event handlers (implemented as needed)
  handleKeyDown(e) {
    // Only handle Enter key for form submission
    if (e.key === "Enter") {
      const form = this.element.closest("form");
      if (!form) return;

      // If this is a submit button, check form validity and submit
      if (
        this.element.type === "submit" ||
        (this.element.tagName === "BUTTON" && this.element.type === "submit")
      ) {
        const allInputsValid = this.validateForm(form);
        if (allInputsValid) {
          this.element.click();
        }
        return;
      }

      // For textareas, allow default behavior
      if (this.element.tagName === "TEXTAREA") {
        return;
      }

      // For all other inputs, try to submit if form is valid
      const submitButton =
        form.querySelector('button[type="submit"]') ||
        form.querySelector('input[type="submit"]');

      if (submitButton && !submitButton.disabled) {
        const allInputsValid = this.validateForm(form);
        if (allInputsValid) {
          submitButton.click();
        }
      }
    }
  }

  /**
   * Validate all form fields
   */
  validateForm(form) {
    const inputs = form.querySelectorAll("input, select, textarea");
    for (const input of inputs) {
      const control = input.closest(".input-control");
      if (!control) continue;

      // Check for required fields
      if (input.hasAttribute("required") && !input.value.trim()) {
        return false;
      }

      // Check for invalid fields (even if not required)
      if (control.classList.contains("invalid")) {
        return false;
      }
    }
    return true;
  }

  handlePaste(e) {}
  handleCut(e) {}
  handleCopy(e) {}
  handleDrop(e) {}
  handleKeyPress(e) {}

  /**
   * Set input mask
   * @param {string|function} mask - Mask pattern or function
   */
  setMask(mask) {
    this.options.mask = mask;

    // Remove existing mask listeners if any
    if (this.maskListener) {
      this.element.removeEventListener("input", this.maskListener);
    }

    // Create new mask listener
    this.maskListener = (e) => {
      const value = e.target.value;
      const position = e.target.selectionStart;

      // Apply mask
      const maskedValue =
        typeof mask === "function" ? mask(value) : this.applyMask(value, mask);

      // Update value if changed
      if (maskedValue !== value) {
        e.target.value = maskedValue;

        // Calculate new cursor position
        let newPosition = position;
        if (typeof mask === "string") {
          // Count actual input characters and mask characters before cursor
          const valueBeforeCursor = value.slice(0, position);
          const inputCharsBeforeCursor = valueBeforeCursor.replace(
            /[^0-9a-zA-Z]/g,
            ""
          ).length;

          // Find corresponding position in masked value
          let count = 0;
          for (let i = 0; i < maskedValue.length; i++) {
            if (/[0-9a-zA-Z]/.test(maskedValue[i])) {
              if (count === inputCharsBeforeCursor) {
                newPosition = i;
                break;
              }
              count++;
            } else if (count === inputCharsBeforeCursor) {
              newPosition = i;
              break;
            }
          }
        }

        // Ensure position is within bounds
        newPosition = Math.min(newPosition, maskedValue.length);
        e.target.setSelectionRange(newPosition, newPosition);
      }
    };

    // Attach mask listener
    this.element.addEventListener("input", this.maskListener);

    // Apply initial mask if value exists
    if (this.element.value) {
      this.element.dispatchEvent(new Event("input"));
    }
  }

  /**
   * Apply mask pattern to value
   * @param {string} value - Input value
   * @param {string} pattern - Mask pattern
   * @returns {string} Masked value
   */
  applyMask(value, pattern) {
    let result = "";
    let valueIndex = 0;

    // Process each character in the pattern
    for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
      const maskChar = pattern[i];
      const valueChar = value[valueIndex];

      switch (maskChar) {
        case "#": // Digit
          if (/\d/.test(valueChar)) {
            result += valueChar;
            valueIndex++;
          }
          break;
        case "A": // Letter
          if (/[a-zA-Z]/.test(valueChar)) {
            result += valueChar;
            valueIndex++;
          }
          break;
        case "*": // Any character
          result += valueChar;
          valueIndex++;
          break;
        default: // Static character
          result += maskChar;
          if (valueChar === maskChar) {
            valueIndex++;
          }
      }
    }

    return result;
  }

  /**
   * Stub for formatter functionality - will implement fully when handling numeric inputs
   * @param {Function} formatter - Formatter function
   */
  addFormatter(formatter) {
    // Temporarily stubbed - will implement when handling numeric inputs
    this.options.formatters.push(formatter);
  }
}
