/**
 * Firebase Storage utilities for legal documents
 * @module modules/legal/utils/storage
 */

import { STORAGE_PATHS, FILE_EXTENSIONS } from "../config/index.js";

/**
 * Get the storage reference for a legal document
 * @param {string} documentId - The document identifier
 * @param {string} extension - The file extension
 * @returns {string} The full storage path
 */
export const getStoragePath = (documentId, extension = FILE_EXTENSIONS.PDF) => {
  return `${STORAGE_PATHS.PDF}/${documentId}${extension}`;
};

/**
 * Get the markdown file path for a document
 * @param {string} documentId - The document identifier
 * @returns {string} The markdown file path
 */
export const getMarkdownPath = (documentId) => {
  return `${STORAGE_PATHS.MARKDOWN}/${documentId}${FILE_EXTENSIONS.MARKDOWN}`;
};

/**
 * Generate metadata for a legal document
 * @param {Object} fileMetadata - The file metadata from Storage
 * @returns {Object} Formatted metadata object
 */
export const generateMetadata = (fileMetadata) => {
  return {
    updatedAt: fileMetadata.updated || new Date().toISOString(),
    version: fileMetadata.md5Hash || "1.0.0",
    size: fileMetadata.size || 0,
  };
};
