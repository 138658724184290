/**
 * Legal Documents Module
 * Handles the display and management of legal documents
 * @module modules/legal
 */

import "./styles/base.css";
import { loadDocument } from "./utils/loader.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from "../../main.js";
import { getStoragePath } from "./utils/storage.js";

export class LegalDocuments {
  constructor() {
    this.container = null;
    this.activeTab = "terms";
    this.isVisible = false;
    this.isLoading = false;
  }

  /**
   * Initialize the modules
   */
  async init() {
    console.log("Initializing Legal Documents module");

    // Get the container
    this.container = document.getElementById("fsLegalContainer");
    if (!this.container) {
      console.log("Creating legal container");
      this.container = document.createElement("div");
      this.container.id = "fsLegalContainer";
      this.container.className = "legal-docs";
      document.body.insertBefore(this.container, document.body.firstChild);
    }

    this.container.innerHTML = `
      <div class="legal-docs-header">
        <h2 class="legal-docs-title">Terms of Use</h2>
        <button class="legal-docs-close" aria-label="Close">×</button>
      </div>
      <div class="legal-download"><i></i>Download as .pdf</div>
      <div class="legal-docs-content">
        <div class="legal-docs-loading" style="display: none;">
          <div class="legal-docs-spinner"></div>
        </div>
        <div class="legal-docs-document"></div>
        <div class="legal-docs-metadata">
          <span class="legal-docs-version"></span>
          <span class="legal-docs-updated"></span>
        </div>
      </div>
      <div class="legal-docs-top"></div>
      <div class="legal-docs-tabs">
        <button class="legal-docs-tab legal-docs-tab--active" data-tab="terms" data-title="Terms of Use">Terms</button>
        <button class="legal-docs-tab" data-tab="privacy" data-title="Privacy Policy">Privacy</button>
        <button class="legal-docs-tab" data-tab="cookies" data-title="Cookies Policy">Cookies</button>
      </div>
    `;

    // Set up event listeners
    this.setupEventListeners();

    // Get the clean path for history
    let cleanPath = "/";

    // Check URL parameters for direct document access
    const urlParams = new URLSearchParams(window.location.search);
    const docParam = urlParams.get("doc");

    console.log("docParam", docParam, urlParams);

    if (docParam && ["terms", "privacy", "cookies"].includes(docParam)) {
      // Set clean path
      cleanPath = `/${docParam}`;

      // Update the URL to look clean
      window.history.replaceState({}, "", cleanPath);

      // Show the panel with the specified document
      await this.show(docParam);
    }

    return this;
  }

  /**
   * Set up event listeners
   * @private
   */
  setupEventListeners() {
    // Scroll to top
    const contentBox = this.container.querySelector(".legal-docs-content");
    contentBox.scrollTo({ top: 0, behavior: "smooth" });

    // Close button
    const closeBtn = this.container.querySelector(".legal-docs-close");
    closeBtn.addEventListener("click", () => this.hide());

    // Download button
    const downloadBtn = this.container.querySelector(".legal-download");
    downloadBtn.addEventListener("click", () => this.downloadPdf());

    // Tab navigation
    const tabs = this.container.querySelectorAll(".legal-docs-tab");
    tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        const tabId = e.target.dataset.tab;
        this.switchTab(tabId);
      });
    });

    // Legal links in footer
    const legalLinks = document.querySelectorAll('[data-action="show-legal"]');
    legalLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        const tab = e.target.dataset.tab;
        this.show(tab);
      });
    });

    // Focus on container
    contentBox.focus();

    // ESC key to close
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && this.isVisible) {
        contentBox.blur();
        this.hide();
      }
    });

    // Set scroll to top button
    const scrollMe = document.querySelector(".legal-docs-top");

    // Scroll to top button
    contentBox.addEventListener("scroll", function () {
      // Set scroll top and button classes
      const currentScrollTop = contentBox.scrollTop;
      if (currentScrollTop > 200) {
        scrollMe.classList.add("show");
      } else {
        scrollMe.classList.remove("show");
      }
    });

    // Scroll to top button
    scrollMe.addEventListener("click", function () {
      contentBox.scrollTo({ top: 0, behavior: "smooth" });
    });
  }

  /**
   * Switch active tab
   * @param {string} tabId - The ID of the tab to switch to
   * @private
   */
  async switchTab(tabId) {
    if (this.isLoading) return;

    const tabs = this.container.querySelectorAll(".legal-docs-tab");
    tabs.forEach((tab) => {
      tab.classList.toggle("legal-docs-tab--active", tab.dataset.tab === tabId);
    });

    const title = this.container.querySelector(".legal-docs-title");
    const curTab = this.container.querySelector(".legal-docs-tab--active");
    console.log("Switching tab to", tabId);
    title.textContent = `${curTab.dataset.title}`;

    // Scroll to top
    const contentBox = this.container.querySelector(".legal-docs-content");
    contentBox.scrollTo({ top: 0, behavior: "smooth" });

    this.activeTab = tabId;
    await this.loadContent(tabId);
  }

  /**
   * Load document content
   * @param {string} documentId - The document to load
   * @private
   */
  async loadContent(documentId) {
    if (this.isLoading) return;

    const loadingEl = this.container.querySelector(".legal-docs-loading");
    const documentEl = this.container.querySelector(".legal-docs-document");
    const versionEl = this.container.querySelector(".legal-docs-version");
    const updatedEl = this.container.querySelector(".legal-docs-updated");
    console.log("Loading content for", documentId);
    try {
      this.isLoading = true;
      loadingEl.style.display = "flex";
      documentEl.style.opacity = "0.5";

      const { content, metadata } = await loadDocument(documentId);

      // Update content with proper sanitization and structure
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = content;

      // Check if we have a legal-content div
      const legalContent = tempContainer.querySelector(".legal-content");
      if (legalContent) {
        // If we have the div, use its contents
        documentEl.innerHTML = legalContent.innerHTML;
      } else {
        // If not, wrap the content in our own structure
        documentEl.innerHTML = `
          <div class="legal-content">
            ${content}
          </div>
        `;
      }
      documentEl.style.opacity = "1";

      // Update metadata
      versionEl.textContent = `Version ${metadata.version}`;
      updatedEl.textContent = `Updated ${new Date(metadata.updatedAt).toLocaleDateString()}`;
    } catch (error) {
      console.error("Error loading document:", error);

      // Check if it's a permissions error
      const isPermissionsError =
        error.code === "permission-denied" ||
        error.message?.includes("Missing or insufficient permissions");

      // Show appropriate error message
      documentEl.innerHTML = `
        <div class="legal-docs-error">
          <h3>${isPermissionsError ? "Document Not Available" : "Error Loading Document"}</h3>
          <p>${
            isPermissionsError
              ? "This legal document is being prepared and will be available soon."
              : "Sorry, we couldn't load the document. Please try again later."
          }</p>
        </div>
      `;

      // Clear metadata for unavailable documents
      versionEl.textContent = "";
      updatedEl.textContent = "";
    } finally {
      this.isLoading = false;
      loadingEl.style.display = "none";
    }
  }

  /**
   * Show the legal documents panel
   * @param {string} [tab] - Optional tab to show (terms, privacy, cookies)
   */
  async show(tab) {
    if (!this.container) {
      console.error("Legal container not initialized");
      return;
    }

    document.body.classList.add("fs-legal-show");
    this.isVisible = true;

    if (tab) {
      await this.switchTab(tab);
    } else {
      // Even if no tab specified, ensure content is loaded
      await this.loadContent(this.activeTab);
    }
  }

  /**
   * Hide the legal documents panel
   */
  hide() {
    // Focus on container
    const contentBox = this.container.querySelector(".legal-docs-content");
    document.body.classList.remove("fs-legal-show");
    contentBox.blur();
    this.isVisible = false;
  }

  /**
   * Toggle the panel visibility
   */
  toggle() {
    this.isVisible ? this.hide() : this.show();
  }

  /**
   * Download the current document as PDF
   * @private
   */
  async downloadPdf() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;
      const storage = getStorage(app);
      const pdfPath = getStoragePath(this.activeTab);
      const pdfRef = ref(storage, pdfPath);

      // Get the download URL
      const downloadURL = await getDownloadURL(pdfRef);

      // Create a temporary link and trigger download
      const link = document.createElement("a");
      link.href = downloadURL;
      link.download = `${this.activeTab}.pdf`; // Forces download
      link.target = "_blank"; // Prevents opening in same tab
      link.rel = "noopener noreferrer"; // Security best practice
      link.type = "application/pdf"; // Explicitly set content type
      link.style.display = "none"; // Hide the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      // You might want to show a user-friendly error message here
    } finally {
      this.isLoading = false;
    }
  }
}
