// Configuration for each step in the signup flow
export const formSteps = {
  "enter-email": {
    formId: "emailForm",
    states: {
      initial: {
        title: "Enter your email to check if you're on the list.",
        instruction: "If your email is found, we will send you a code.",
        action: "enter email",
        button: {
          text: "Check Invitation",
          submittingText: "Field Empty",
        },
      },
      dirty: {
        button: {
          submittingText: "Invalid Entry",
        },
      },
      validating: {
        button: {
          submittingText: "Checking...",
        },
      },
      valid: {
        button: {
          submittingText: "Valid Format",
        },
      },
      invalid: {
        instruction: "Email not found. Please try again.",
        button: {
          text: "Try Again",
          submittingText: "Invalid Entry",
        },
      },
      confirming: {
        instruction: "Please confirm your email address to continue.",
        button: {
          text: "Confirm Email",
          submittingText: "Invalid Entry",
        },
      },
    },
    // Keep existing properties for backward compatibility
    title: "Enter your email to check if you're on the list.",
    instruction: "If your email is found, we will send you a code.",
    inputTemplateId: "enterEmailInput",
    button: {
      text: "Check Invitation",
      submittingText: "Field Empty",
    },
  },
  "verify-email": {
    formId: "verifyEmailForm",
    states: {
      initial: {
        title: "Verify Your Email",
        instruction:
          "Please enter the 6-digit code sent to your email. A verification code has been sent to: <strong class='sent-email'></strong>. Please check your spam folder if you don't see the email.",
        action: "verify email",
        button: {
          text: "Verify Email",
          submittingText: "Verifying...",
        },
      },
      invalid: {
        instruction: "Invalid code. Please try again.",
        button: {
          text: "Try Again",
        },
      },
      resending: {
        instruction: "Sending new code...",
        button: {
          text: "Verify Email",
          submittingText: "Sending...",
        },
      },
    },
    // Keep existing properties for backward compatibility
    title: "Verify Your Email",
    instruction: "Please enter the 6-digit code sent to your email.",
    inputTemplateId: "verifyEmailInput",
    button: {
      text: "Verify Email",
      submittingText: "Verifying...",
    },
  },
  "enter-phone": {
    formId: "phoneForm",
    title: "Enter your Phone Number",
    instruction:
      "Please enter your cell number <em>(US phones number only)</em>.",
    inputTemplateId: "phoneInput",
    button: {
      text: "Verify Phone Number",
      submittingText: "Validating...",
    },
  },
  "verify-phone": {
    formId: "verifyPhoneForm",
    title: "Verify Your Phone Number",
    instruction: "Please enter the 6-digit code sent to your cell phone",
    inputTemplateId: "verifyPhoneInput",
    button: {
      text: "Verify Phone",
      submittingText: "Verifying...",
    },
  },
  "enter-name": {
    formId: "nameForm",
    title: "Enter Your Name",
    instruction: "Enter your first and last name in the space provided.",
    inputTemplateId: "nameInputs",
    button: {
      text: "Continue",
      submittingText: "Saving...",
    },
  },
  "set-password": {
    formId: "passwordForm",
    title: "Create Password",
    instruction: "Choose a secure password for your account.",
    inputTemplateId: "passwordInputs",
    button: {
      text: "Create My Account",
      submittingText: "Creating Account...",
    },
  },
};
